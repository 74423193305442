@media (min-width: 1420px) {
  .container {
    max-width: 1400px;
  }
}

/* colors */

body {
  margin: 0;
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
  color: #eee;
  padding: 0;

  line-height: 125%;
}

.site {
  position: relative;
  height: 100%;
  min-height: 100vh;
}

h1,
h2,
h3,
h4 {
  font-weight: 400;
  margin: 1rem 0;
  line-height: 1.15;
}

h1 {
  font-size: 32px;
  line-height: 56px;
}

p {
  margin: 0 0 1rem 0;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.dark h1 {
  text-shadow: 0 0 5px #000;
}

.light h1 {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

a {
  color: #fff;
  transition: color 0.3s ease;
  text-decoration: none;
}

p a {
  color: rgb(89, 189, 255);
  transition: color 1s ease;
  text-decoration: none;
}

p a:hover {
  color: rgb(0, 153, 255);
}

a:hover {
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  font-size: 24px;
  font-weight: 300;
  font-family: "Raleway";
  line-height: 5rem;
  overflow: auto;
}

header a {
  text-decoration: none;
  color: #bbb;
}

header .title {
  color: #fff;
  float: left;
}
header ul {
  float: right;
  width: auto;
  margin: 0;
  list-style: none;
  padding-left: 0;
}
header ul li {
  float: left;
  line-height: 5rem;
}
header ul li a {
  padding: 0 1rem;
  border-right: 1px solid #999;
  line-height: 2.5rem;
}
header ul li:last-child a {
  border-right: 0;
  line-height: 2.5rem;
}

.container-fluid.container-main {
  position: absolute;
  top: 52px;
  bottom: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.container-fluid.container-main .row {
  position: relative;
  height: 100%;
}
.container-fluid > .row > .col-sm,
.container-fluid > .row > .col-xs {
  padding: 0;
}

.container.container-page {
  padding: 2rem 4rem;
}

.container-fluid.container-overview .image {
  width: 100%;
  padding-bottom: 100%;
}

.hover-image {
  display: block;
  height: 100%;
  min-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}

.hover-image .image-content {
  display: block;
  height: 100%;
  min-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}

.photography .hover-image .image-content {
  width: 100%;
  padding-bottom: 150%;
}

.photography .hover-image .caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
  text-align: center;
  transition: all 1s ease;
  background: rgba(0, 0, 0, 0.3);
}
.photography .hover-image:hover .caption {
  background: rgba(0, 0, 0, 0.2);
}

.hover-image p {
  height: 2.625rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.game-design .hover-image .image-content {
  width: 100%;
  padding-bottom: 56.25%;
}

.game-design .col-sm-12 .hover-image .image-content {
  width: 100%;
  padding-bottom: 40%;
}

.game-design .container {
  background-color: rgba(0, 0, 0, 0.4);
}

.game-detail p,
.game-detail ul {
  color: #ccc;
}

.game-detail .lead {
  font-size: 19px;
  color: #fc0;
}

.HomePage .hover-image .image-content {
  width: 100%;
  padding-bottom: 30%;
}

.HomePage .container {
  background-color: rgba(0, 0, 0, 0.61);
}

.HomePage .container2 {
  background-color: rgba(0, 0, 0, 0.61);
  padding-bottom: 40%;
}

.image {
  display: block;
  height: 100%;
  min-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
}

.image-portrait {
  height: auto;
  min-height: initial;
  padding-top: 150%;
}

.image-landscape {
  height: auto;
  min-height: initial;
  padding-top: 75%;
}

.hover-image .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.content {
  display: flex;
  align-items: center;
}

.content .container {
  flex-direction: column;
  padding: 1rem 0;
}

.hover-image .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.centered {
  width: 100%;
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
}

@media (min-width: 576px) {
  .container-fluid.container-main .row > .col-sm,
  .container-fluid.container-main .row > .col-xs {
  }

  .game-design .hover-image {
  }

  .hover-image {
    overflow: hidden;
  }

  .hover-image .overlay {
    transition: all 1s ease;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 35px 0 rgba(0, 0, 0, 0.2);
  }
  .hover-image:hover .overlay {
    background-color: rgba(0, 0, 0, 0);
    /* box-shadow: none; */
  }

  .game-design .hover-image .overlay {
    transition: all 1s ease;
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 0 100px 0 rgba(0, 0, 0, 0.3);
  }
  .game-design .hover-image:hover .overlay {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 55px 0 rgba(0, 0, 0, 0.2);
    /* box-shadow: none; */
  }

  .game-design .hover-image .image-content {
    transition: all 0.4s ease;
    transform: scale(1);
  }

  .game-design .hover-image:hover .image-content {
    transform: scale(1.3);
    /* box-shadow: none; */
  }

  .photography .hover-image .image-content {
    transition: all 0.7s ease;
    transform: scale(1);
  }

  .photography .hover-image:hover .image-content {
    transform: scale(1.1);
    /* box-shadow: none; */
  }
}

@media (max-width: 767px) {
  header {
    font-size: 18px !important;
    height: 3rem;
  }
  .container-fluid.container-main {
    top: 3rem;
  }
  header ul {
    clear: left;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 48px;
    line-height: 54px;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 60px;
    line-height: 64px;
  }
}

.text-center {
  text-align: center;
  min-width: 100%;
}
